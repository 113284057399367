
import { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate, useMatch, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Card4 } from "../../../../../../_metronic/partials/content/cards/Card4";
import { useViewLandContext } from "./ViewLandContext";
import { LoadingCard } from "../../../../../../_metronic/elements/Loading";
import { useViewImageContext } from "./Images/ViewImageContext";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { Ambients, ViewAmbientProvider, DialogAmbient, useViewAmbientContext } from "./Ambients";
import { useMapLegendContext } from "./Helpers/MapLegend";
import Layers from "./Layers";
import Images from "./Images";
import Prescription from "./Prescription";
import ImageAmbient from "./ImageAmbients";
import UpdateLand from "../UpdateLand";
import DeleteLand from "../DeleteLand";
import DeleteAmbient from "./Ambients/DeleteAmbient";
import NatalseedsLandMenu from "./Natalseeds/NatalseedsLandMenu";
import NatalseedsCampaign from "./Natalseeds/NatalseedsCampaign";
import './ViewLand.style.scss';
import { useMapLayersV2 } from "../../../Map/MapLayersV2Context";

const LandMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const viewLandContext = useViewLandContext();
  const tenant_domain = useMemo(() => {
    return window.tenant_domain;
  }, []);

  return (
    <>
      {tenant_domain == "natalseeds.app" && (<NatalseedsLandMenu />)}
      <div className='row g-0' style={{ borderBottom: '1px solid var(--kt-card-border-color)' }}>
        <div className='col-4 col-sm-4 col-xl-4' onClick={(e) => {
          e.preventDefault();
          navigate(`/gis/field/view/${viewLandContext.land.field.field_id}/land/view/${viewLandContext.land.land_id}`)
        }}>
          <Card4
            icon='/media/icons/duotune/maps/map010.svg'
            title={t("lands:view.menu.images")}
            active={useMatch('/gis/field/view/:field_id/land/view/:land_id') != null}
          />
        </div>
        <div className='col-4 col-sm-4 col-xl-4' onClick={(e) => {
          e.preventDefault();
          navigate(`/gis/field/view/${viewLandContext.land.field.field_id}/land/view/${viewLandContext.land.land_id}/ambients`)
        }}>
          <Card4
            icon='/media/icons/duotune/maps/map002.svg'
            title={t("lands:view.menu.ambients")}
            active={useMatch('/gis/field/view/:field_id/land/view/:land_id/ambients') != null}
          />
        </div>
        <div className='col-4 col-sm-4 col-xl-4' onClick={(e) => {
          e.preventDefault();
          navigate(`/gis/field/view/${viewLandContext.land.field.field_id}/land/view/${viewLandContext.land.land_id}/layers`)
        }}>
          <Card4
            icon='/media/icons/duotune/abstract/abs027.svg'
            title={t("lands:view.menu.layers")}
            active={useMatch('/gis/field/view/:field_id/land/view/:land_id/layers') != null}
          />
        </div>
      </div>
    </>
  );
};

const PrescriptionDialog = () => {
  const viewImageContext = useViewImageContext();
  const viewAmbientContext = useViewAmbientContext();
  return (
    <Prescription
      open={viewImageContext.openPrescription}
      setOpen={viewImageContext.setOpenPrescription}
      ambient={
        viewAmbientContext.selectedElement === 'imageAmbient' ?
        viewAmbientContext.imageAmbientData :
         viewAmbientContext.ambientData
      } />
  )
}

const ImageAmbientDialog = () => {
  const viewImageContext = useViewImageContext();
  const viewAmbientContext = useViewAmbientContext();
  return (
    <ImageAmbient
      open={viewImageContext.openImageAmbient}
      setOpen={viewImageContext.setOpenImageAmbient}
      ambient={viewAmbientContext.ambientData} /> //TODO: Remove ambient data
  )
}

const ViewLand = () => {
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteLand, setDeleteLand] = useState(false);
  const mapLegendContext = useMapLegendContext();
  const mapLayers = useMapLayersV2();
  const viewLandContext = useViewLandContext();
  const viewImageContext = useViewImageContext();
  const { land_id } = useParams();
  const { t } = useTranslation();
  const tenant_domain = useMemo(() => {
    return window.tenant_domain;
  }, []);

  useEffect(() => {
    viewLandContext.setLandId(land_id);
    mapLegendContext.recoverIfExists(land_id);
    
  }, [land_id, viewLandContext]);

  useEffect(() => {
    if (viewLandContext.land != null) {
      viewImageContext.addLand(viewLandContext.land);
      mapLegendContext.deleteAll();
      mapLegendContext.recoverIfExists(viewLandContext.land.land_id);
    }
  }, [viewLandContext.land, mapLegendContext]);

  useEffect(() => {
    return () => {
      viewLandContext.stopViewingLand(land_id);
    };
  }, []);

  if (viewLandContext.loading || !viewLandContext.land) {
    return <LoadingCard className="view-land-container displayable" />;
  }

  return (
    <>
      <ViewAmbientProvider>
        <div className="card shadow-sm view-land-container displayable right-card-data">
          <div className="card-header collapsible">
            <h3 className="card-title cursor-pointer" style={{ width: "125px" }} data-bs-toggle="collapse" data-bs-target="#kt_view_land_collapsible">{viewLandContext.land.name}</h3>
            <div className="card-toolbar">
              <button type="button" onClick={() => mapLayers.fit(land_id)} class="btn btn-color-muted btn-active-color-primary btn-sm me-2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <KTSVG
                  path="/media/icons/duotune/maps/map007.svg"
                  className="svg-icon svg-icon-2x"
                />
              </button>
              <button type="button" onClick={() => setDeleteLand(true)} className="btn btn-sm btn-light-danger me-2">
                {t("lands:view.buttons.delete")}
              </button>
              <button type="button" onClick={() => setUpdateOpen(true)} className="btn btn-sm btn-light-primary">
                {t("lands:view.buttons.update")}
              </button>
            </div>
          </div>
          <div id="kt_view_land_collapsible" className="collapse show">
            <div className="card-body" style={{ padding: 0, paddingTop: '0px' }}>
              <LandMenu />
              <Routes>
                <Route path="/" element={<Images />} />
                <Route path="/layers" element={<Layers />} />
                <Route path="/ambients" element={<Ambients />} />
              </Routes>
            </div>
          </div>
        </div>
        <PrescriptionDialog />
        <ImageAmbientDialog />
        <DialogAmbient />
        <DeleteAmbient />
      </ViewAmbientProvider>
      <UpdateLand open={updateOpen} setOpen={setUpdateOpen} />
      <DeleteLand open={deleteLand} setOpen={setDeleteLand} />
      {tenant_domain == "natalseeds.app" && <NatalseedsCampaign />}
    </>
  );
}


export default ViewLand;